
import { Component, Watch } from 'vue-property-decorator';
import MetricPage from '@/components/analytics/MetricPage.vue';
import { Venue } from '@/interfaces/models/Venue';
import { VenueLabel } from '@/interfaces/models/VenueLabel';
import { namespace } from 'vuex-class';
import VWrapper from '@/components/shared/VWrapper.vue';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';
import AnalyticsLoader from '@/components/analytics/AnalyticsLoader.vue';
import Filter from '@/interfaces/api/Filter';
import { Range } from '@/interfaces/components/Range';
import Statistic from '@/interfaces/models/Statistic';
import { mixins } from 'vue-class-component';
import CustomerGroup from '@/mixins/CustomerGroup';
import { SelectItem } from '@/interfaces/components/SelectItem';
import { AxiosResponse } from 'axios';
import StatisticsApiService from '@/api/http/StatisticsApiService';
import { toUrl } from '@/util/helper';
import moment, { Moment } from 'moment';
import { Permission } from '@/enums/Permission';
import Countries from '@/mixins/Countries';
import TimeSelector from '@/components/analytics/TimeSelector.vue';

const venue = namespace('venue');
const venueLabel = namespace('venueLabel');
const analytics = namespace('analytics');

@Component({
  components: { AnalyticsLoader, VRangePicker, MetricPage, VWrapper, TimeSelector },
})
export default class Analytics extends mixins(CustomerGroup, Countries) {
  @analytics.Action('fetch') public getAnalytics: any;
  @analytics.Action('fetchByGroup') public getCustomerAnalytics: any;
  @analytics.Action('setFilter') public setFilter: any;

  @analytics.State('item') public metric!: Statistic;
  @analytics.State('filter') public filter!: Filter;

  @venue.State('active') public venue!: Venue;

  @venueLabel.State('items') public venueLabels!: VenueLabel[];
  @venueLabel.Action('fetch') public getVenueLabels!: any;

  public customerGroup: CustomerGroup | '' = '';
  public selectedLabel: string = '';
  public selectedCountry: string = '';

  public rangeWithDate: Range = { from: '', to: '' };
  public timeFromInput = { openedAt: '', closedAt: '' };

  get rangeWithTime() {
    const dateRange = this.rangeWithDate;
    const timeRange = this.timeFromInput;

    let fromDate = moment(dateRange.from);
    let toDate = moment(dateRange.to);

    // if openedAt and closedAt not selected, add one day for correct getting data
    if (!timeRange.openedAt || timeRange.openedAt === '' || !timeRange.closedAt || timeRange.closedAt === '') {
      if (fromDate.isSame(toDate)) {
        toDate = toDate.add(1, 'day');
      }
      return { from: fromDate.format('YYYY-MM-DDTHH:mm'), to: toDate.format('YYYY-MM-DDTHH:mm') };
    }

    const openedAt = moment.utc(timeRange.openedAt, 'HH:mm');
    const closedAt = moment.utc(timeRange.closedAt, 'HH:mm');

    fromDate = fromDate.set({ hour: openedAt.hours(), minute: openedAt.minutes() });
    toDate = toDate.set({ hour: closedAt.hours(), minute: closedAt.minutes() });

    if (toDate.isBefore(fromDate)) {
      toDate = toDate.add(1, 'day');
    }

    const preparedRange = { from: fromDate.format('YYYY-MM-DDTHH:mm'), to: toDate.format('YYYY-MM-DDTHH:mm') };

    return preparedRange;
  }

  get range() {
    if (this.filter.from && this.filter.to) {
      return { from: this.filter.from, to: this.filter.to };
    }

    return;
  }

  get scopedCustomerGroups() {
    if (this.$isAdmin()) {
      return this.customerGroups;
    }
    if (this.$hasCustomerGroup()) {
      return this.customerGroups.filter((i: SelectItem) => i.value === '' || i.value === this.$getCustomerGroup());
    }

    return [];
  }

  public async reloadAnalytics() {
    this.$startLoading('analytics');
    if (this.$can(Permission.LABEL_VIEW)) {
      await this.getVenueLabels();
    }

    await this.getAnalytics();
    this.$stopLoading('analytics');
  }

  public async reloadCustomerAnalytics() {
    this.$startLoading('analytics');
    await this.getCustomerAnalytics(this.rangeWithTime);
    this.$stopLoading('analytics');
  }

  public created() {
    this.$startLoading('analytics');
  }

  public getRange(range?: Range): Range {
    if (!range) {
      return { from: new Date().toISOString().substr(0, 10), to: new Date().toISOString().substr(0, 10) };
    }

    return range;
  }

  @Watch('customerGroup')
  public onCustomerGroupChange() {
    // @ts-ignore
    let group: string | number = this.customerGroup;
    if (group === 0) {
      group = '';
    } else if (group === 1) {
      group = this.$getCustomerGroup();
    }
    // @ts-ignore
    const { from, to }: { from: string; to: string } = this.filter;
    if (group && group !== '') {
      this.selectedLabel = '';
      if (from && to) {
        this.setFilter({ group, from, to });
        this.reloadCustomerAnalytics();
      }
    }
    if (group === '') {
      if (from && to) {
        const filter: Filter = { from, to, venue: this.venue._id };
        if (this.selectedLabel) {
          filter.venueLabel = this.selectedLabel;
        }
        this.setFilter(filter);
        this.reloadAnalytics();
      }
    }
  }

  public onChangeLabel(label: string) {
    this.customerGroup = '';
    this.setFilter({ ...this.filter, venueLabel: label });
    this.reloadAnalytics();
  }

  public exportCsv() {
    this.$startLoading('analyticsCsv');
    const api: StatisticsApiService = new StatisticsApiService();
    api
      .getAnalyticsCsv({
        venue: this.filter.venue,
        from: this.filter.from,
        country: this.selectedCountry,
        to: this.filter.to,
        venueLabel: this.filter.venueLabel,
        lang: this.$i18n.locale,
      })
      .then((res: AxiosResponse) => toUrl(res.data, `analytics-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('analyticsCsv');
      });
  }

  public onChange(range: Range) {
    this.rangeWithDate = range;
  }

  onTime($event: any) {
    this.timeFromInput = $event;
  }

  @Watch('venue')
  public onVenueChange() {
    if (this.venue && this.venue._id) {
      this.customerGroup = '';
      this.selectedLabel = '';
      this.selectedCountry = '';
      this.setFilter({ venue: this.venue._id, ...this.getRange(this.range), country: this.selectedCountry });
      this.reloadAnalytics();
    }
  }

  @Watch('rangeWithTime')
  public reloadAnalyticByTime() {
    if (this.venue && this.venue._id && this.rangeWithTime) {
      this.setFilter({ ...this.filter, venue: this.venue._id, ...this.rangeWithTime, country: this.selectedCountry });
      if (this.customerGroup) {
        this.reloadCustomerAnalytics();
      } else {
        this.reloadAnalytics();
      }
    }
  }
}
