
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { Bar, mixins } from 'vue-chartjs';
import 'chartjs-plugin-datalabels';

const { reactiveProp } = mixins;

@Component
export default class LineChart extends Mixins(Bar, reactiveProp) {
  @Prop({}) public options!: any;
  @Prop({ type: Number }) public chartWidth!: number;

  public gradient: any = null;

  public mounted() {
    // @ts-ignore
    this.$el.firstElementChild.parentElement!.classList.add('canvas-parent');
    this.showChart();
  }

  public showChart() {
    this.renderChart(this.chartData, this.options);
    this.applyGradient();
  }

  public applyGradient() {
    const canvas: HTMLCollectionOf<HTMLElement> = this.$el.getElementsByTagName('canvas')!;
    if (canvas && canvas[0]) {
      // @ts-ignore
      this.gradient = canvas[0].getContext('2d').createLinearGradient(0, 0, 0, 1000);
      this.gradient.addColorStop(0, 'rgb(138, 44, 140)');
      this.gradient.addColorStop(0.5, 'rgba(60, 8, 140)');
    }
    if (this.$data._chart) {
      this.$data._chart.config.data.datasets[0].backgroundColor = this.gradient;
      this.$data._chart.update();
    }
  }

  @Watch('chartData')
  public onChartDataChange() {
    this.showChart();
  }

  @Watch('chartWidth')
  public onChartWidthChange() {
    this.showChart();
  }
}
