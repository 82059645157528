
import { Component, Prop, Vue } from 'vue-property-decorator';
import Charts from '@/components/analytics/Charts.vue';
import Statistic from '@/interfaces/models/Statistic';
import InfoBox from '@/components/analytics/InfoBox.vue';
import SingleButton from '@/components/analytics/SingleButton.vue';
import IconClose from '@/components/analytics/IconClose.vue';
import _ from 'lodash';
import uuid from 'uuid/v4';
import VRangePicker from '@/components/shared/form/VRangePicker.vue';

interface Range {
  from: string;
  to: string;
}

interface ChartData {
  labels: string[];
  datasets: Dataset[];
}

interface Dataset {
  data: number[];
}

interface MonthData {
  [monthYear: string]: { labels: string[]; data: number[] };
}

enum Period {
  MONTHLY = 'monthly',
  DAILY = 'daily',
}
@Component({
  components: { Charts, IconClose, SingleButton, InfoBox, VRangePicker },
})
export default class MetricPage extends Vue {
  @Prop({ type: Object, default: null }) public metric!: Statistic;
  @Prop({ type: Object, default: {} }) public range!: Range;
  public selectedSection: null | string = null;
  public selectedPeriod: Period = Period.DAILY;
  public openedStat: null | string = null;
  public colors: any = {
    paypal: '#4E948D',
    cash: '#467D8C',
    credit_card: '#8DC1B9',
    sepa: '#759E9B',
    balance: '#517B73',
    pos: '#88AC9A',
    apple_pay: '#D9D79D',
    google_pay: '#E0BA68',
    sofortbanking: '#EDD6B3',
  };
  public period = Period;

  get buttonsInfo(): Array<{
    name: string;
    disabled: boolean;
    value: number | string | null | undefined;
    extraInfo?: string | null;
    key: string;
  }> {
    return [
      { name: 'totalNumberOfOrders', value: this.metric.totalNumberOfOrders, disabled: false, key: uuid() },
      { name: 'avgOrdersPerDay', value: this.metric.avgOrdersPerDay.toFixed(0), disabled: true, key: uuid() },
      { name: 'avgAmount', value: this.formatPrice(this.metric.avgAmount), disabled: false, key: uuid() },
      { name: 'totalAmount', value: this.formatPrice(this.metric.totalAmount), disabled: false, key: uuid() },
      { name: 'totalSoldArticles', value: this.metric.totalSoldArticles, disabled: false, key: uuid() },
      {
        name: 'highestDaysSale',
        value: this.formatPrice(this.metric.highestDaysSale),
        disabled: true,
        extraInfo: this.getMaxDaySale,
        key: uuid(),
      },
      { name: 'totalPreCheckouts', value: this.metric.totalPreCheckouts, disabled: true, key: uuid() },
      {
        name: 'paymentMethod',
        value: this.metric.payment!.bestMethod || '-',
        disabled: _.isEmpty(this.metric.payment!.bestMethod),
        key: uuid(),
      },
      {
        name: 'avgOrderDuration',
        value: this.metric.avgOrderDuration || '0' + ' sec',
        disabled: true,
        key: uuid(),
      },
      { name: 'totalTimeouts', value: this.metric.totalTimeouts, disabled: true, key: uuid() },
    ];
  }

  get bestSold() {
    if (this.metric && this.metric.articles) {
      const tmp = Array.from(this.metric.articles);
      return tmp.splice(0, 3);
    }
  }

  get worstSold() {
    if (this.metric && this.metric.articles) {
      const tmp = Array.from(this.metric.articles);
      return tmp.splice(3, 6);
    }
  }

  get getMaxDaySale() {
    if (this.isMetricReady) {
      for (const day of this.metric.days) {
        if (day.totalSum === this.metric.highestDaysSale) {
          return day.date;
        }
      }
    }
    return null;
  }

  get canvasWidth() {
    return this.dataset.datasets[0].data.length * 75;
  }

  get isMetricReady() {
    return !_.isEmpty(this.metric);
  }

  get getMaxPerDay() {
    if (this.metric && this.metric.days) {
      return this.metric.days.reduce((prev, current) =>
        prev.numberOfOrders > current.numberOfOrders ? prev : current,
      );
    }
    return 0;
  }

  get options() {
    if (this.selectedSection === 'paymentMethod') {
      return {
        plugins: {
          datalabels: {
            align: 'center',
            anchor: 'center',
            color: '#fff',
            font: {
              weight: 'bold',
            },
            tooltips: { enabled: false },
            display(context: any) {
              return context.dataset.data[context.dataIndex] >= 1;
            },
            formatter: (value: any) => {
              return value + '%';
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        ticks: {
          min: 1,
        },
        legend: {
          display: true,
        },
        scales: {
          xAxes: [
            {
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
              beginAtZero: true,
            },
          ],
        },
      };
    }

    return {
      plugins: {
        datalabels: {
          align: 'top',
          anchor: 'end',

          font: {
            weight: 'bold',
          },
          formatter: (value: any) => {
            switch (this.selectedSection) {
              case 'totalNumberOfOrders':
                return value;
              case 'avgAmount':
                return this.formatPrice(value);
              case 'totalAmount':
                return this.formatPrice(value);
              case 'totalSoldArticles':
                return value;
              default:
                return this.formatPrice(value);
            }
          },
        },
      },
      legend: { display: false },
      tooltips: { enabled: false },
      responsive: true,
      maintainAspectRatio: false,
      layout: {
        padding: {
          top: 25,
        },
      },
    };
  }

  get dataset(): any {
    const labels: any = [];
    const data: any = [];
    if (this.metric && this.metric.days && this.selectedSection) {
      this.metric.days.map((stat) => labels.push(stat.date));
    } else {
      if (this.metric && this.metric.monthlySales) {
        this.metric.monthlySales.map((stat) => labels.push(stat._id));
        this.metric.monthlySales.map((stat) => data.push(stat.total));
      }
    }

    if (this.metric && this.metric.days) {
      switch (this.selectedSection) {
        case 'totalNumberOfOrders':
          this.metric.days.map((stat) => data.push(stat.numberOfOrders));
          break;
        case 'avgAmount':
          this.metric.days.map((stat) => data.push(stat.averageAmount.toFixed(2)));
          break;
        case 'totalAmount':
          this.metric.days.map((stat) => data.push(stat.totalSum));
          break;
        case 'totalSoldArticles':
          this.metric.days.map((stat) => data.push(stat.numberOfSoldArticles));
          break;
        case 'paymentMethod':
          const multiDataset: any = [];
          const multiLabels: any = [];
          this.metric.payment!.days.map((stat) => multiLabels.push(stat._id));
          this.metric.payment!.days.map((stat) => {
            Object.keys(stat)
              .splice(2, Object.keys(stat).length)
              .map((name: any) => {
                if (multiDataset.findIndex((data: any) => data.label === name) === -1) {
                  multiDataset.push({
                    label: name,
                    data: [],
                    backgroundColor: '',
                  });
                }
              });
          });

          multiDataset.map((payment: any) => {
            this.metric.payment!.days.map((stat: any) => {
              Object.keys(stat)
                .splice(2, Object.keys(stat).length)
                .map((name: any) => {
                  if (payment.label === name) {
                    multiDataset[multiDataset.findIndex((data: any) => data.label === name)].data.push(
                      (stat[name].percentage * 100).toFixed(2),
                    );
                    multiDataset[multiDataset.findIndex((data: any) => data.label === name)].backgroundColor =
                      this.colors[name];
                  }
                });
            });
          });

          return {
            labels: multiLabels,
            datasets: multiDataset.filter((item: any) => item.label !== '_id' && item.label !== 'total'),
          };
        default:
          break;
      }
    }

    const dataset: ChartData = {
      labels,
      datasets: [
        {
          data,
        },
      ],
    };
    if (this.selectedPeriod === Period.MONTHLY) {
      return this.datasetByMonth(dataset);
    }

    return dataset;
  }

  public datasetByMonth(data: ChartData) {
    const separatedData: MonthData = this.separateAndSumDataByMonth(data);
    const summedData: { [monthYear: string]: ChartData } = this.sumDataByMonth(separatedData);

    return this.aggregateSummedData(summedData);
  }

  public separateAndSumDataByMonth(data: ChartData): MonthData {
    const separatedData: MonthData = {};
    data.labels.forEach((label: string, index: number) => {
      const month = label.substring(0, 7); // Extracting year-month ("2024-01")
      if (!separatedData[month]) {
        separatedData[month] = {
          labels: [],
          data: [],
        };
      }
      const dataIndex = separatedData[month].labels.indexOf(label);
      if (dataIndex === -1) {
        separatedData[month].labels.push(label);
        separatedData[month].data.push(+data.datasets[0].data[index]);
      } else {
        separatedData[month].data[dataIndex] += +data.datasets[0].data[index];
      }
    });
    return separatedData;
  }

  public sumDataByMonth(data: MonthData): { [monthYear: string]: ChartData } {
    const summedData: { [monthYear: string]: ChartData } = {};
    for (const month in data) {
      const sum = data[month].data.reduce((acc: any, curr: any) => acc + curr, 0);
      summedData[month] = {
        labels: data[month].labels,
        datasets: [
          {
            data: [sum],
          },
        ],
      };
    }
    return summedData;
  }

  private aggregateSummedData(summedData: { [monthYear: string]: ChartData }): ChartData {
    const metrics: ChartData = { labels: Object.keys(summedData), datasets: [{ data: [] }] };

    metrics.labels.forEach((label: string) => {
      metrics.datasets[0].data.push(...summedData[label].datasets[0].data);
    });

    return metrics;
  }

  public formatPrice(value: number) {
    const val = (value / 1).toFixed(2).replace('.', ',');
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + '€';
  }

  public selectSection(num: string | null) {
    if (this.selectedSection === num) {
      this.selectedSection = null;
    } else {
      this.selectedSection = num;
    }
    // @ts-ignore
    this.$refs.scrollable.scrollLeft = 0;
  }

  public selectPeriod(value: Period) {
    this.selectedPeriod = value;
  }

  public exportCanvas() {
    if (this.$refs.chart) {
      // @ts-ignore
      for (const child of this.$refs.chart.$el.children) {
        if (child.nodeName.toLowerCase() === 'canvas') {
          const link = document.createElement('a');
          link.download = 'monthly-revenue.png';
          // @ts-ignore
          link.href = child.toDataURL('image/png');
          link.click();
          break;
        }
      }
    }
  }

  public openStat(key: string) {
    if (this.openedStat === key) {
      this.openedStat = null;
    } else {
      this.openedStat = key;
    }
  }
}
