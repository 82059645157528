
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class SingleButton extends Vue {
  @Prop({ type: String }) public selectedSection!: string;
  @Prop({ type: String }) public name!: string;
  @Prop({ type: [String, Number] }) public value!: string | number;
  @Prop({ type: String }) public extraInfo!: string;
  @Prop({ type: Boolean }) public disabled!: boolean;
}
