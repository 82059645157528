
import { Component, Vue, Watch, Emit, Prop } from 'vue-property-decorator';

@Component({})
export default class VRangePicker extends Vue {
  @Prop({ type: Boolean, default: true }) public initData!: boolean;

  public form: { from: string; to: string } = {
    from: '',
    to: '',
  };
  public fromMenu: boolean = false;
  public toMenu: boolean = false;

  @Emit('change')
  @Emit('input')
  @Watch('form', { deep: true })
  public handleFormChange() {
    return this.form;
  }

  public mounted() {
    if (this.initData) {
      this.setToday();
    }
  }

  public setToday() {
    this.form.from = new Date().toISOString().substr(0, 10);
    this.form.to = new Date().toISOString().substr(0, 10);
  }
}
