
import { Component, Prop, Vue } from 'vue-property-decorator';

import IconClose from './IconClose.vue';

@Component({
  components: { IconClose },
})
export default class InfoBox extends Vue {
  @Prop({ type: Array }) public items!: any[];

  public getPercentage(ratio: string): string {
    const percent: number = parseFloat(ratio) * 100;
    return percent.toFixed(2);
  }
}
